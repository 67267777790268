import type { HandleClientError } from '@sveltejs/kit';
import { sentryCaptureException } from '$lib/sentry';

// https://github.com/sveltejs/kit/discussions/4315#discussioncomment-3484236
// Diagram showing when things get executed this file seemed to be the earliest code execution
// so that's why I tried importing here (and hopefully getting the side effects in so grpcTransport is defined)

// This is actually important to keep here. Because of our weirdness with globalThis and getting namespaces.
// There's a race condition in dev that makes it so when we try and access grpcTransport in transport.ts
// SOMEHOW grpcTransport isn't initialized? No idea how don't ask questions. So to make sure that
// it IS initialized before any other code tries to use it we import setGlobalConfig. It doesn't even
// add the stuff to the globalThis at this point. And if you import grpcTransport directly here to try and get it initialized? No dice.
// It's weird and I don't understand it but it works. Sorry y'all - Alex T.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { setGlobalConfig } from '$lib/utilities/set-global-config';

export const handleError: HandleClientError = async ({ error }) => {
	sentryCaptureException(error);
	return error as Error;
};
